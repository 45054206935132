exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-magic-mirror-js": () => import("./../../../src/pages/magic-mirror.js" /* webpackChunkName: "component---src-pages-magic-mirror-js" */),
  "component---src-pages-photography-js": () => import("./../../../src/pages/photography.js" /* webpackChunkName: "component---src-pages-photography-js" */),
  "component---src-templates-blog-element-template-js": () => import("./../../../src/templates/blogElementTemplate.js" /* webpackChunkName: "component---src-templates-blog-element-template-js" */),
  "component---src-templates-photo-element-template-js": () => import("./../../../src/templates/photoElementTemplate.js" /* webpackChunkName: "component---src-templates-photo-element-template-js" */),
  "component---src-templates-photography-element-template-js": () => import("./../../../src/templates/photographyElementTemplate.js" /* webpackChunkName: "component---src-templates-photography-element-template-js" */)
}

